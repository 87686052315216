import { useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { ConfirmDialog } from "src/components/confirm";
import Stack from "src/components/stack";
import SearchIcon from "../../../icons/Search";
import Scrollbar from "../../Scrollbar";
import Trash from "../../../icons/Trash";
import EditIcon from "@material-ui/icons/Edit";
import { SocketContext } from "../../../contexts/SocketContext";
import useAuth from "../../../hooks/useAuth";

const applyFilters = (products, query, filters) =>
  products.filter((project) => {
    let matches = true;

    if (query && !project.name.toLowerCase().includes(query.toLowerCase())) {
      matches = false;
    }

    if (filters.category && project.category !== filters.category) {
      matches = false;
    }

    if (filters.availability) {
      if (filters.availability === "available" && !project.isAvailable) {
        matches = false;
      }

      if (filters.availability === "unavailable" && project.isAvailable) {
        matches = false;
      }
    }

    if (
      filters.inStock &&
      !["in_stock", "limited"].includes(project.inventoryType)
    ) {
      matches = false;
    }

    if (filters.isShippable && !project.isShippable) {
      matches = false;
    }

    return matches;
  });

const applyPagination = (products, page, limit) =>
  products.slice(page * limit, page * limit + limit);

const ProductListTable = ({ projects }) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [query, setQuery] = useState("");
  const {
    user: { accessLevel },
  } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const connection = useContext(SocketContext);
  const [filters] = useState({
    category: null,
    availability: null,
    inStock: null,
    isShippable: null,
  });

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  // Usually query is done on backend with indexing solutions
  const filteredProducts = applyFilters(projects, query, filters);
  const paginatedProducts = applyPagination(filteredProducts, page, limit);

  const handleDeleteProject = (uid) => {
    connection.socket.emit(
      "projects:deleteProject",
      { projectUID: uid },
      ({ status, message }) => {
        enqueueSnackbar(message, { variant: status });
      },
    );
  };

  return (
    <Card>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          m: -1,
          p: 2,
        }}
      >
        <Box
          sx={{
            m: 1,
            maxWidth: "100%",
            width: 500,
          }}
        >
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder="Поиск по названию проекта"
            value={query}
            variant="outlined"
          />
        </Box>
      </Box>
      <Divider />
      <Scrollbar>
        <Box sx={{ minWidth: 1200 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Имя</TableCell>
                <TableCell>Адрес</TableCell>
                <TableCell>Описание</TableCell>
                <TableCell>IP проекта</TableCell>
                {accessLevel > 4 && (
                  <TableCell align="right">Actions</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedProducts.map((project) => {
                return (
                  <TableRow hover key={project.uid}>
                    <TableCell>
                      <Link
                        color="textPrimary"
                        component={RouterLink}
                        to={`/products/${project.uid}`}
                        underline="none"
                        variant="subtitle2"
                      >
                        {project.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {project.address.length > 15
                        ? `${project.address.slice(0, 15)}...`
                        : project.address}
                    </TableCell>
                    <TableCell>{project.description}</TableCell>
                    <TableCell>{project.ip}</TableCell>
                    {accessLevel > 4 && (
                      <TableCell align="right">
                        <Stack>
                          <Button
                            sx={{
                              backgroundColor: "primary.main",
                              "&:hover": {
                                backgroundColor: "primary.dark",
                              },
                            }}
                            size="small"
                            variant="contained"
                            component={RouterLink}
                            to={`edit/${project.uid}`}
                            // onClick={() => {
                            //   handleEdit(project.uid);
                            // }}
                          >
                            <EditIcon size="small" />
                          </Button>
                          <ConfirmDialog
                            actionMessage={
                              <Typography variant={"subtitle1"}>
                                Внимание! Удаление проекта
                                <Typography
                                  sx={{ fontWeight: "bold" }}
                                  component={"span"}
                                >{` "${project.name}"`}</Typography>
                              </Typography>
                            }
                            onSubmit={() => {
                              handleDeleteProject(project.uid);
                            }}
                          >
                            {(open) => (
                              <Button
                                sx={{
                                  ml: 1,
                                  backgroundColor: "error.main",
                                  "&:hover": {
                                    backgroundColor: "error.dark",
                                  },
                                }}
                                size="small"
                                variant="contained"
                                onClick={open}
                              >
                                <Trash size="small" />
                              </Button>
                            )}
                          </ConfirmDialog>
                        </Stack>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredProducts.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Box>
      </Scrollbar>
    </Card>
  );
};

ProductListTable.propTypes = {
  projects: PropTypes.array.isRequired,
};

export default ProductListTable;
